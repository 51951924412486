import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'

export interface UserDropdownProps {
    value: any
    onChange(event: any, data: any): void
}


const SdgDropdown: React.FunctionComponent<UserDropdownProps> = ({ value, onChange }) => {
    const { t } = useTranslation()
    const sdgOptions = Array.from({ length: 17 }, (_, index) => {
        const value = index + 1;
        return {
            text: t(`sdg.${value}`),
            value: value,
            icon: <img src={`/images/sdg/${value}.svg`} alt={t(`sdg.${value}`)} />,
        };
    });


    return (
        <Dropdown
            fluid
            multiple
            selection
            value={value}
            onChange={onChange}
            options={sdgOptions}
        >
        </Dropdown>
    )
}
export default SdgDropdown