import React, { useEffect } from 'react'
import { Button, Container, Form, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react'
import Loading from '../../../common/Loading'
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import * as API from '../../../../api'
import { useForm } from 'react-hook-form';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { Save } from 'react-feather';
import { getState } from '../../../../state';
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../../actions/error';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IUserIce } from '../../../../api/user';
import ControlTextArea from '../../../fields/ControlTextArea';

const userIceSchema = z.object ({
    id: z.number().optional(),
    userIce1: z.string().optional(),
    userIce2: z.string().optional()
});

const UserIceModule = () => {

    const { t } = useTranslation()
    const {
        dispatch, 
    } = getState()

    const {
        isLoading,
        data: userIce,
        refresh,
    } = useGetQueryByParams(true, API.user.getUserIce, "userIce")

    const { control, handleSubmit, reset } = useForm<IUserIce>({
        resolver: zodResolver(userIceSchema),
    })

    useEffect(() => {
        if (userIce) {
            reset({ 
                userIce1: userIce.userIce1 ?? "",
                userIce2: userIce.userIce2 ?? ""
            });
       
        } else {
            reset({ userIce1: "", userIce2: "" });
        }
    }, [userIce, reset]);

    const onUserIceRequest = async (data: IUserIce) => {
        if (userIce?.userId) {
            return await API.user.editUserIce(userIce.userId, data)
        } else {
            return await API.user.createUserIce(data)
        }
    };

     const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserIceRequest, {
            onError: (error: any) => {
                handleAxiosError(dispatch)(error);
            },
            onSuccess: () => {
                toast.success(t('saved'));
                refresh();
            },
        });

    if (isLoading) {
        return <Loading />
    }

    return (

        <Container>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <Grid stackable>
                    <GridRow columns="1">
                        <GridColumn>
                            <Header>
                            {t('userIceFormHeader')}
                            </Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlTextArea name="userIce1" label={t('userIce1')} placeholder='Namn, relation och telefonnummer' control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlTextArea name="userIce2" label={t('userIce2')} placeholder='Namn, relation och telefonnummer' control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1" textAlign='right'>
                       <GridColumn >
                            <Button
                                type="submit" 
                                primary
                                disabled={false}
                                icon 
                                labelPosition='left'>
                                <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                                {t('save')}
                            </Button>
                       </GridColumn>
                    </GridRow>

                </Grid>
            </Form>
        </Container>
    );
};

export default UserIceModule;