import React, { useState, useEffect, useRef } from 'react'
import { HelpCircle, Info, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import {
    Button,
    Checkbox,
    CheckboxProps,
    Container,
    Dimmer,
    DropdownProps,
    Form,
    Grid,
    Header,
    Input,
    InputProps,
    Loader,
    Message,
    Popup,
    Segment,
    TextArea,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { hasModule, MODULES } from '../../../auth/modules'
import { AdminRoles } from '../../../auth/roles'
import { Goal, useGoal } from '../../../hooks/goals'
import {
    PeriodType,
    useGoalMeasurement,
    useStandardMeasurements,
} from '../../../hooks/goals/measurement'
import { useIsInRole } from '../../../hooks/role'
import { getState } from '../../../state'
import CollapsingSegment from '../../common/collapsingSegment'
import DateInput, { DateOnChangeData } from '../../common/dateInput'
import DepartmentDropdown from '../../common/departmentDropdown'
import ErrorBox from '../../common/errorBox'
import Files from '../../common/files'
import FlexBox from '../../common/flexBox'
import LocationDropdown from '../../common/locationDropdown'
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup'
import { UserDropdown } from '../../common/userDropdown'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import ActivitiesTable from './activitiesTable'
import AddMeasurementValueModal from '../common/addMeasurementValueModal'
import DecisionsTable from './decisionsTable'
import MeasurementSegment from './measurementSegment'
import TopDiagram from './topDiagram'
import DocumentSectionModule from '../../modules/docs/viewPage/DocumentSectionModule'
import { RisksTableSectionModule } from '../../modules/docs/viewPage/Risks/RisksTable'
import PrintWrapper from '../../wrappers/PrintWrapper'
import SdgDropdown from '../../fields/SdgDropdown'

export const ColoredSegment = styled(Segment)`
    background-color: ${props => (props.$closed ? '#def7ad !important' : undefined)};
`

const CustomSdgLabel = styled.label`
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
`
const GoalDetailsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { id, parentId } = useParams()
    const printRef = useRef(null)
    const navigate = useNavigate()
    const { state } = getState()

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)

    const { data: backendGoal, error, loading: goalLoading, save } = useGoal(id)

    const {
        data: measurement,
        create: addMeasurmentValue,
        error: saveMeasurementDataError,
    } = useGoalMeasurement(id ?? backendGoal?.id?.toString())

    const { addValue: addStandardMeasurementValue } = useStandardMeasurements()

    const [editingGoal, setEditingGoal] = useState<Goal>()

    useEffect(() => {
        setEditingGoal(backendGoal)
    }, [backendGoal])

    const updateEditingGoal = <T extends unknown>(key: string, value: T) => {
        setEditingGoal(g => ({
            ...(g ?? {}),
            [key]: value,
        }))
    }

    const onSave = () =>
        save(editingGoal!, parentId).then(() => toast(`${t('saved')}!`, { type: 'success' }))

    return (
        <div ref={printRef} style={{ flexGrow: 1 }}>
            <PrintWrapper title={editingGoal?.title || ""}>
                <Container>
                    <Dimmer active={!editingGoal && goalLoading}>
                        <Loader />
                    </Dimmer>

                    {backendGoal?.id && editingGoal?.title && <Header className='hide-in-print'>{editingGoal.title}</Header>}

                    {measurement?.id && (
                        <>
                            {isAdmin && (
                                <FlexBox justifyContent="end">
                                    <AddMeasurementValueModal
                                        onCreate={data =>
                                            backendGoal?.connectedMeasurement?.isStandard
                                                ? addStandardMeasurementValue(measurement.id!, data)
                                                : addMeasurmentValue(measurement.id!, data)
                                        }
                                        error={saveMeasurementDataError}
                                        periodType={measurement.periodId ?? PeriodType.Yearly}
                                        trigger={
                                            <Button icon labelPosition="left" primary type="button">
                                                <SemanticFeatherIcon
                                                    FeatherIcon={Plus}
                                                    size={'60%'}
                                                    centerIcon={true}
                                                />{' '}
                                                {t('newMeasurementValue')}
                                            </Button>
                                        }
                                    />
                                </FlexBox>
                            )}
                            <FlexBox justifyContent="center" style={{ padding: '5px 20px 20px 20px' }}>
                                <TopDiagram goal={backendGoal} measurement={measurement} />
                            </FlexBox>
                        </>
                    )}

                    <ErrorBox error={error} />

                    <ColoredSegment
                        basic={!backendGoal?.id}
                        $closed={backendGoal?.status && backendGoal?.status > 1}
                    >
                        <SaveCancelPrintButtonGroup
                            location="top"
                            onSave={onSave}
                            disableSave={
                                !isAdmin ||
                                !editingGoal?.title ||
                                !editingGoal.startDate ||
                                !editingGoal.latestDate ||
                                !editingGoal.responsibleId ||
                                !editingGoal.departmentId ||
                                (!!editingGoal.connectedMeasurement?.id &&
                                    !editingGoal.initialValueId &&
                                    !editingGoal.isKpi)
                            }
                            onCancel={() => navigate(-1)}
                            printComponentRef={printRef}
                            savePopupText={t('onlyAdminSave')}
                        />
                        <Form>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Field
                                            required
                                            label={t('topic')}
                                            control={Input}
                                            value={editingGoal?.title ?? ''}
                                            onChange={(_: Event, data: InputProps) =>
                                                updateEditingGoal('title', data.value)
                                            }
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Form.Field
                                            required
                                            label={t('startDate')}
                                            control={DateInput}
                                            value={editingGoal?.startDate ?? ''}
                                            onChange={(_: Event, data: DateOnChangeData) =>
                                                updateEditingGoal('startDate', data.value)
                                            }
                                            clearable={false}
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            required
                                            label={t('endDate')}
                                            control={DateInput}
                                            clearable={false}
                                            value={editingGoal?.latestDate}
                                            onChange={(_: Event, data: DateOnChangeData) =>
                                                updateEditingGoal('latestDate', data.value)
                                            }
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                required
                                                label={t('responsible')}
                                                control={UserDropdown}
                                                selection
                                                search
                                                value={editingGoal?.responsibleId ?? ''}
                                                onChange={(_: Event, data: DropdownProps) =>
                                                    updateEditingGoal('responsibleId', data.value)
                                                }
                                                readOnly={!isAdmin}
                                            />

                                            <Form.Field
                                                required
                                                label={t('department')}
                                                control={DepartmentDropdown}
                                                selection
                                                value={editingGoal?.departmentId ?? ''}
                                                onChange={(_: Event, data: DropdownProps) =>
                                                    updateEditingGoal('departmentId', data.value)
                                                }
                                                readOnly={!isAdmin}
                                            />

                                            {!!state.locations?.length && (
                                                <Form.Field
                                                    label={t('locationId')}
                                                    control={LocationDropdown}
                                                    selection
                                                    value={editingGoal?.locationId ?? ''}
                                                    onChange={(_: Event, data: DropdownProps) =>
                                                        updateEditingGoal(
                                                            'locationId',
                                                            data.value ? data.value : undefined,
                                                        )
                                                    }
                                                    readOnly={!isAdmin}
                                                    clearable
                                                />
                                            )}
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Field

                                            label={<FlexBox gap="6px"><CustomSdgLabel>{t('sdg')}</CustomSdgLabel><Popup
                                                content={t('sdg.tooltip')}
                                                trigger={
                                                    <SemanticFeatherIcon FeatherIcon={HelpCircle} size="32px" centerIcon />

                                                } /></FlexBox>}
                                            control={SdgDropdown}
                                            value={editingGoal?.goalSDGs}
                                            onChange={(_: Event, data: DropdownProps) =>
                                                updateEditingGoal("goalSDGs", data.value)
                                            }
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Field
                                            label={t('description')}
                                            control={TextArea}
                                            value={editingGoal?.description ?? ''}
                                            onChange={(_: Event, data: DropdownProps) =>
                                                updateEditingGoal('description', data.value)
                                            }
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Field
                                            label={t('kpi')}
                                            control={Checkbox}
                                            checked={editingGoal?.isKpi ?? false}
                                            onChange={(_: Event, data: CheckboxProps) =>
                                                updateEditingGoal('isKpi', data.checked ?? false)
                                            }
                                            readOnly={!isAdmin}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <MeasurementSegment
                                onMeasurementSelected={(connectedMeasurement, initialValueId) =>
                                    setEditingGoal(g => ({
                                        ...g,
                                        connectedMeasurement,
                                        initialValueId,
                                    }))
                                }
                                isKpi={editingGoal?.isKpi}
                                connectedMeasurement={editingGoal?.connectedMeasurement}
                                initialValueId={editingGoal?.initialValueId}
                                targetValue={editingGoal?.targetValue}
                                targetValueUpdated={value => updateEditingGoal('targetValue', value)}
                                isClosed={!!backendGoal?.status && backendGoal?.status > 1}
                            />

                            {backendGoal?.id && (
                                <CollapsingSegment heading={t('close')}>
                                    {!!backendGoal?.hasOpenChildren ? (
                                        <Message
                                            info
                                            icon={
                                                <SemanticFeatherIcon
                                                    FeatherIcon={Info}
                                                    size="32px"
                                                    centerIcon={true}
                                                />
                                            }
                                            content={t('cantCloseGoalIfThereAreOpenChildren')}
                                        />
                                    ) : (
                                        <Grid columns={2}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Form.Field
                                                        label={t('endedDate')}
                                                        control={DateInput}
                                                        value={editingGoal?.endedDate ?? ''}
                                                        onChange={(_: Event, data: DateOnChangeData) =>
                                                            updateEditingGoal('endedDate', data.value)
                                                        }
                                                        readOnly={!isAdmin}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Field
                                                        label={t('endedBy')}
                                                        control={Input}
                                                        value={editingGoal?.endedBy ?? ''}
                                                        onChange={(_: Event, data: InputProps) =>
                                                            updateEditingGoal('endedBy', data.value)
                                                        }
                                                        readOnly={!isAdmin}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    )}
                                </CollapsingSegment>
                            )}
                        </Form>
                    </ColoredSegment>

                    {backendGoal?.id && (
                        <>
                            <DocumentSectionModule title={t('decisions')} attachTop={isAdmin}>
                                <DecisionsTable goalId={backendGoal.id.toString()} />
                            </DocumentSectionModule>

                            <DocumentSectionModule title={t('activities')} attachTop={isAdmin}>
                                <ActivitiesTable
                                    goalId={backendGoal.id.toString()}
                                    isClosed={!!backendGoal?.status && backendGoal?.status > 1}
                                />
                            </DocumentSectionModule>

                            <DocumentSectionModule title={t('files')} attachTop>
                                <Files
                                    module="Goals"
                                    collectionId={backendGoal.id.toString()}
                                    mayAutoUpload
                                    enableDragDrop
                                    disableDelete={!isAdmin}
                                />
                            </DocumentSectionModule>

                            {hasModule(state)(MODULES.RISKS) && (
                                <RisksTableSectionModule connectedId={backendGoal.id} type="goal" />
                            )}
                        </>
                    )}
                </Container>
            </PrintWrapper>
        </div>
    )
}

export default GoalDetailsPage
