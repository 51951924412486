import React, { useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalHeader,
    ModalContent,
    Form,
    ModalActions,
    Button,
    Dropdown,
    Input,
    InputOnChangeData,
} from 'semantic-ui-react'
import { MeasurementData, PeriodType } from '../../../hooks/goals/measurement'
import ActionButton from '../../common/actionButton'
import ErrorBox from '../../common/errorBox'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { createYearOptions, useMonthOptions, createWeekOptions } from '../goalDetails/options'

export interface AddMeasurementValueModalProps {
    periodType: PeriodType
    error: any
    trigger: React.ReactNode
    onCreate: (data: MeasurementData) => Promise<unknown>
}

const initalData: MeasurementData = {
    value: 0,
    year: new Date().getFullYear(),
}

const AddMeasurementValueModal: React.FunctionComponent<AddMeasurementValueModalProps> = ({
    periodType,
    error,
    trigger,
    onCreate,
}) => {
    const { t } = useTranslation()
    const monthOptions = useMonthOptions()
    const [open, setOpen] = useState(false)
    const [newMeasurementValue, setNewMeasurementValue] = useState<MeasurementData>({
        ...initalData,
    })

    const handleMeasurementValueChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        { name, value }: InputOnChangeData,
    ) => {
        console.log(name, value)
        setNewMeasurementValue({ ...newMeasurementValue, [name]: value })
    }

    const onClose = () => {
        setNewMeasurementValue({ ...initalData })
        setOpen(false)
    }

    const isValidInput =
        (periodType === PeriodType.Yearly || newMeasurementValue.timeReference) &&
        newMeasurementValue.year &&
        Number.isInteger(newMeasurementValue.value)

    return (
        <Modal onClose={onClose} onOpen={() => setOpen(true)} open={open} trigger={trigger}>
            <ModalHeader>{t('newMeasurementValue')}</ModalHeader>
            <ModalContent>
                <ErrorBox error={error} />

                <Form>
                    <Form.Group widths="equal">
                        <Form.Field
                            required
                            control={Dropdown}
                            name="year"
                            value={newMeasurementValue.year ?? ''}
                            label={t('year')}
                            onChange={handleMeasurementValueChange}
                            options={createYearOptions()}
                            selection
                        />

                        {periodType !== PeriodType.Yearly &&
                            (periodType === PeriodType.Monthly ? (
                                <Form.Field
                                    required
                                    control={Dropdown}
                                    name="timeReference"
                                    value={newMeasurementValue.timeReference ?? ''}
                                    label={t('month')}
                                    onChange={handleMeasurementValueChange}
                                    options={monthOptions}
                                    selection
                                />
                            ) : (
                                <Form.Field
                                    required
                                    control={Dropdown}
                                    name="timeReference"
                                    value={newMeasurementValue.timeReference ?? ''}
                                    label={t('week')}
                                    onChange={handleMeasurementValueChange}
                                    options={createWeekOptions()}
                                    selection
                                />
                            ))}
                    </Form.Group>

                    <Form.Field
                        required
                        type="number"
                        control={Input}
                        name="value"
                        value={newMeasurementValue.value ?? 0}
                        label={t('value')}
                        onChange={handleMeasurementValueChange}
                    />
                </Form>
            </ModalContent>
            <ModalActions>
                <ActionButton
                    disabled={!isValidInput}
                    action={() => onCreate(newMeasurementValue)}
                    postActionCallback={onClose}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>
                <Button onClick={onClose} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default AddMeasurementValueModal
