import { post, put, get } from "../restClient";

export interface IClothesSize {
    userId: number,
    pantSize: string,
    shirtSize: string,
    shoeSize: string,
    gloveSize: string
}

export interface IUserIce {
    userId: number,
    userIce1: string,
    userIce2: string
}

export interface IChangePasswordRequest {
    oldPassword: string,
    newPassword: string
}

export const editClothesSize =(id: number, data: IClothesSize) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize`, data) 

export const createClothesSize = (data: IClothesSize) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize`, data)

export const getClothesSize = () => get<IClothesSize>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize`)

export const getUserIce = () => get<IUserIce>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce`)

export const editUserIce = (id: number, data: IUserIce) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce`, data)

export const createUserIce = (data: IUserIce) =>post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce`, data)

export const changePassword = (data: IChangePasswordRequest) =>post(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/password/change`, data)