import React, { useEffect } from 'react'
import { Button, Container, Form, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react'
import Loading from '../../../common/Loading'
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import * as API from '../../../../api'
import { useForm } from 'react-hook-form';
import ControlInput from '../../../fields/ControlInput';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { Save } from 'react-feather';
import { getState } from '../../../../state';
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../../actions/error';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IClothesSize } from '../../../../api/user';

const clothesSizeSchema = z.object({
    id: z.number().optional(),
    pantSize: z.string().optional(),
    shirtSize: z.string().optional(),
    shoeSize: z.string().optional(),
    gloveSize: z.string().optional(),
});

const UserInfoModule = () => {

    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()

    const {
        isLoading,
        data: clothesSize,
        refresh,
    } = useGetQueryByParams(true, API.user.getClothesSize, "clothesSize");

    const { control, handleSubmit, reset } = useForm<IClothesSize>({
        resolver: zodResolver(clothesSizeSchema),
    });

    useEffect(() => {
        if (clothesSize) {
            reset({
                pantSize: clothesSize.pantSize ?? "",
                shirtSize: clothesSize.shirtSize ?? "",
                shoeSize: clothesSize.shoeSize ?? "",
                gloveSize: clothesSize.gloveSize ?? ""
            });
        } else {
            reset({ pantSize: "", shirtSize: "", shoeSize: "", gloveSize: "" });
        }
    }, [clothesSize, reset]);

    const onClothesSizeRequest = async (data: IClothesSize) => {
        if (clothesSize?.userId) {
            return await API.user.editClothesSize(clothesSize.userId, data);
        } else {
            return await API.user.createClothesSize(data);
        }
    };

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onClothesSizeRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error);
        },
        onSuccess: () => {
            toast.success(t('saved'));
            refresh();
        },
    });

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <Grid stackable>
                    <GridRow columns="1">
                        <GridColumn>
                            <Header>
                            {t('clothesSizeFormHeader')}
                            </Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="pantSize" label={t('pantSize')} placeholder={t('pantSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="shirtSize" label={t('shirtSize')} placeholder={t('shirtSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="shoeSize" label={t('shoeSize')} placeholder={t('shoeSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                
                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="gloveSize" label={t('gloveSize')} placeholder={t('gloveSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1" textAlign='right'>
                        <GridColumn >
                            <Button
                                type="submit" 
                                primary 
                                disabled={false}
                                icon 
                                labelPosition="left">
                                <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                                {t('save')}
                            </Button>
                        </GridColumn>
                    </GridRow>

                </Grid>
            </Form>
        </Container>
    );
};

export default UserInfoModule;