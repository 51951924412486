import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SurveyPage from '../surveys/surveyPage'
import App from './app'
import AppLoader from './appLoader'
import AdminModule from '../admin'
import SupplierDeviationFormPage from '../modules/external/SupplierDeviationFormPage'
import UserInfoModule from '../modules/userInfo/clothesSize/index'
import UserIceModule from '../modules/userInfo/inCaseOfEmergency'
import UserAuthModule from '../modules/userInfo/changePassword'
import UserFormModule from '../modules/userInfo/userForm'

const Routing: React.FunctionComponent = () => (
    <Routes>
        <Route
            path="/surveys/do/:surveyGuid"
            element={<SurveyPage />}
        />
        <Route
            path="/external/supplier/:sId/deviations/:deviationId"
            element={<SupplierDeviationFormPage />}
        />
        <Route
            path="/admin/*"
            element={
                <AppLoader>
                    <AdminModule />
                </AppLoader>
            }
        />
        <Route
            path="/userinfo"
            element={
                <AppLoader>
                    <UserInfoModule />
                </AppLoader>
            }
        />
        <Route
            path="/userice"
            element={
                <AppLoader>
                    <UserIceModule />
                </AppLoader>
            }
        />    
        <Route 
            path="/userauth"
            element={
                <AppLoader>
                    <UserAuthModule />
                </AppLoader>
            }
        />
        <Route 
            path="/userform"
            element={
                <AppLoader>
                    <UserFormModule />
                </AppLoader>
            }
        />

        <Route
            path="*"
            element={
                <AppLoader>
                    <App />
                </AppLoader>
            }
        />
    </Routes>
)

export default Routing